@media screen and (min-width: 601px) {
  * {
    box-sizing: border-box;
  }


  .loader{
    padding: auto;
    margin: auto;
  }

  .spendDate{
    font-size: 2vw;
  }
  .mainTracker {
    width: 35%;
    height: 100%;
    border-right: 1px solid #fff;
    padding-right: 50px;
  }

  main UserButton {
    width: 5%;
  }

  .analytics {
    width: 60%;
    height: 100%;
  }

  main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    height: 100vh;
  }

  body {
    background-color: #17181f;
    color: #ddd;
  }

  h1 {
    text-align: center;
    color: #fff;
    margin: 0;
    font-size: 3vw;
    margin-top: 10px;
  }

  h1 span {
    font-size: 1rem;
    vertical-align: top;
    display: inline-block;
    margin-top: 6px;
  }

  .delete-all{
    margin-top:20px;
  }

  form {
    margin-top: 0px;
  }

  form input {
    width: 100%;
    background-color: transparent;
    color: #ddd;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  form div.basics {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
  }

  form select {
    width: 100%;
    background-color: transparent;
    color: #ddd;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  form div.category-date {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
  }

  form button {
    width: 100%;
    background-color: #ddd;
    margin-top: 10px;
    border: 0;
    border-radius: 5px;
    padding: 5px;
  }

  div.transactions {
    margin-top: 5px;
    overflow-y: auto;
    height: 50%;
  }

  div.transaction {
    display: flex;
    padding: 5px 0;
    border-top: 1px solid #30313d;
  }

  div.transaction:first-child {
    border-top: 0;
  }

  div.transaction div.name {
    font-size: 1.2rem;
  }

  div.transaction div.description {
    font-size: 0.8rem;
    color: #888;
  }

  div.transaction div {
    border: 0;
  }

  div.left {
    width: 30%;
  }
  div.right {
    text-align: right;
    margin-left: auto;
  }

  div.middle {
    margin-left: auto;
    text-align: right;
  }

  div.category {
    margin-left: auto;
    text-align: center;
    width: 20%;
  }

  div.price {
    color: red;
    font-size: 1.2rem;
  }

  div.date {
    font-size: 0.8rem;
    color: #888;
  }

  div.filter {
    display: flex;
    gap: 5px;
    margin-top: 10px;
  }

  div.filter select {
    width: 20%;
    background-color: transparent;
    color: white;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  div.analytics {
    align-items: center;
    margin-bottom: -10%;
  }

  div.analytics h1 {
    text-align: left;
    font-size: 3vw;
  }

  div.pie {
    width: 100%;
    height: 35%;
    margin-top: 25px;
  }

  div.pie input[type="month"] {
    width: 200px;
    margin-left: 20px;
  }

  input[type="month"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  input {
    width: 100%;
    background-color: transparent;
    color: #ddd;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  div.line {
    margin-top: 50px;
    width: 100%;
    height: 35%;
  }

  div.line h2 {
    margin-left: 100px;
    color: #ddd;
  }

  div.pie h2 {
    color: #ddd;
  }

  .SignIn {
    margin-top: 35vh;
  }

  button.delete-btn,
  button.log {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 0 10px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
  }

  .log {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 200px;
    transform: translate(-50%, -50%);
    color: rgb(104, 85, 224);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(104, 85, 224, 1);
  }

  button:hover {
    color: white;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
    background-color: rgba(104, 85, 224, 1);
  }

  .delete-btn {
    width: 20px;
    color: red;
  }

  .edit-btn{
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 0 10px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    width: 40px;
  }

  .float-child {
    width: 5%;
    margin-left: 10px;
    margin-top: 10px;
    float: left;
  }
}

button.delete-all-btn {
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
  transition: 0.4s;
  width: 100%;
  padding:5px;
}

button.delete-all-btn:hover {
  color: white;
  box-shadow: 0 0 10px red;
  background-color: red;
}

/* -------------------------------------------------------------------------- */

@media screen and (max-width: 600px) {
  * {
    box-sizing: border-box;
  }

  .loader{
    padding: auto;
    margin: auto;
  }
  .mainTracker {
    width: 100%;
    height: 80vh;
    border-bottom: 1px solid #fff;
    padding-left: 25px;
    padding-right: 25px;
  }

  main UserButton {
    width: 5%;
  }

  .analytics {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    /* height: 100%; */
  }

  main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    height: 100vh;
  }

  body {
    background-color: #17181f;
    color: #ddd;
  }

  h1 {
    text-align: center;
    color: #fff;
    margin: 0;
    font-size: 5vw;
    margin-top: 10px;
  }

  h1 span {
    font-size: 1rem;
    vertical-align: top;
    display: inline-block;
    margin-top: 6px;
  }

  .delete-all{
    margin-top: 20px;
  }

  form {
    margin-top: 0px;
  }

  form input {
    width: 100%;
    background-color: transparent;
    color: #ddd;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  form div.basics {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
  }

  form select {
    width: 100%;
    background-color: transparent;
    color: #ddd;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  form div.category-date {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
  }

  form button {
    width: 100%;
    background-color: #ddd;
    margin-top: 10px;
    border: 0;
    border-radius: 5px;
    padding: 5px;
  }

  div.transactions {
    margin-top: 5px;
    overflow-y: auto;
    height: 60%;
  }

  div.transaction {
    display: flex;
    padding: 5px 0;
    border-top: 1px solid #30313d;
  }

  div.transaction:first-child {
    border-top: 0;
  }

  div.transaction div.name {
    font-size: 1.2rem;
  }

  div.transaction div.description {
    font-size: 0.8rem;
    color: #888;
  }

  div.transaction div {
    border: 0;
  }

  div.left {
    width: 30%;
  }
  div.right {
    text-align: right;
    margin-left: auto;
  }

  div.middle {
    margin-left: auto;
    text-align: right;
  }

  div.category {
    margin-left: auto;
    text-align: center;
    width: 20%;
  }

  div.price {
    color: red;
    font-size: 1.2rem;
  }

  div.date {
    font-size: 0.8rem;
    color: #888;
  }

  div.filter {
    display: flex;
    gap: 5px;
    margin-top: 10px;
  }

  div.filter select {
    width: 20%;
    background-color: transparent;
    color: white;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  div.analytics {
    align-items: center;
    margin-bottom: -10%;
  }

  div.analytics h1 {
    text-align: left;
    font-size: 5vw;
  }

  div.pie {
    width: 100%;
    height: 35%;
    margin-top: 25px;
  }

  div.pie input[type="month"] {
    width: 200px;
    margin-left: 20px;
  }

  input[type="month"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  input {
    width: 100%;
    background-color: transparent;
    color: #ddd;
    border: 2px solid #30313d;
    padding: 2px 5px;
    border-radius: 5px;
  }

  div.line {
    margin-top: 50px;
    width: 100%;
    height: 35%;
  }

  div.line h2 {
    margin-left: 100px;
    color: #ddd;
  }

  div.pie h2 {
    color: #ddd;
  }

  .SignIn {
    margin-top: 35vh;
  }

  button.delete-btn,
  button.log {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 0 10px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
  }

  button.delete-all-btn {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    width: 100%;
    padding:5px;
  }

  .log {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 200px;
    transform: translate(-50%, -50%);
    color: rgb(104, 85, 224);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(104, 85, 224, 1);
  }

  button:hover {
    color: white;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
    background-color: rgba(104, 85, 224, 1);
  }

  .delete-btn {
    width: 20px;
  }

  .float-child {
    width: 5%;
    margin-left: 10px;
    margin-top: 10px;
    float: left;
  }
}



.spendDate{
  font-size: 2vw;
}